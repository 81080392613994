
.message {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin: 10px;
    border-right: black solid 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
}

.shake:hover {
    transition: transform 0.5s;
    animation: shake 0.5s forwards;
}

@keyframes shake {
    0% {
        transform: rotate(0deg) scale(1);
    }
    20% {
        transform: rotate(-10deg) scale(1.1);
    }
    30% {
        transform: rotate(10deg) scale(1.1);
    }
    60% {
        transform: rotate(-10deg) scale(1.1);
    }
    100% {
        transform: rotate(0deg) scale(1.1);
    }
}