.pointer {
    cursor: pointer !important;
}
.students-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 8px;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 8px;
    transition: all ease-in-out 0.2s;
}
@media (max-width: 800px) {
    .students-container-underlay {
        position: fixed;
    }
    .main-container {
        grid-template-columns: 1fr;
    }
}