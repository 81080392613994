.rotate-175 {
  transform: rotate(175deg);
}


/*.collapse-height {*/
/*  transition: max-height 0.3s ease-out;*/
/*  max-height: 0!important;*/
/*}*/

/*.collapse-height.show {*/
/*  max-height: 300px!important;*/
/*}*/


