.student-card-container {
    height: 80px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    cursor: pointer;
    margin: 4px 0 4px 0 ;
    width: 100%;
    transition: transform ease-in-out 0.1s;
}
.student-card-container:hover {
    transform: translateY(-4px);
    filter: brightness(0.8);
}

.student-card-container:active {
    background-color: #c6d0d6;
}
.picture-container {
    width: 80px;
    height: 80px;
    padding: 8px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selected {
    background-color: #c6d0d6;
}
.student-card-hr {
    width: 1px;
    min-height: 80%;
    margin: auto 8px;
}
.student-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0;
}