.main-container {
    display: grid;
    grid-template-columns: auto 1fr;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.personal-file{
    overflow: auto;
    padding: 10px;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.students-container {
    z-index: 1021;
    padding: 10px;
    position: fixed;
    width: 325px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    transition: transform 0.1s;
}
.closed-students-box {
    width: 0 !important;
    padding: 0 !important;
}
.transform-student-box {
    transform: translateX(120%);
}
.action-svg {
    transition: all 0.1s;
}
.no-students-msg {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 1.5rem;
}
.students-container-underlay {
    width: 325px;
    padding: 8px;
    height: calc(100vh - 100px);
    transition: width 0.1s;
}
.students-container {
    ::-webkit-scrollbar {
        width: 3px;
        background-color: rgba(58, 58, 58, 0.32);
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(92, 92, 92, 0.8);
        border-radius: 10px;

    }
}
.fw-point8rem {
    font-size: .8rem;
}
.w-38px {
    width: 38px;
}
.h-38px {
    height: 38px;
}

@media (max-width: 1200px) {
    .students-container-underlay {
        height: calc(100vh - 92px);

    }
    .students-container {
        height: calc(100vh - 92px);
    }
}
@media (max-width: 800px) {
    .weresoobad {
        position: fixed;
    }
}