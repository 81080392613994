.my-custom-scrollbar {
    position:relative;
    height: auto;
    /*overflow: auto;*/
    overflow-x: auto;
}
/*.table-wrapper-scroll-y {*/
/*    height: 80vh;*/
/*    display: block;*/
/*}*/

.table-header-row{
    border-top-left-radius: 20px!important;
    border-top-right-radius: 20px!important;
    border-bottom: 1px solid #ddd!important;
    background-color: #f5f5f5!important;
    color: #000!important;
    font-weight: bold!important;
    font-size: 1.2em!important;
    text-align: center!important;
    height: 20px!important;

}
.vh-50{
    height: 50vh!important;
}
.rbc-day-bg{
    background-color: #fff7f7;
}
.rbc-month-header{
    background-color: #8a8a8f;
}
.rbc-timeslot-group{
    background-color: #fff7f7;
}
@media (max-width: 992px) {
    .table-wrapper-scroll-y{
        width: 100% !important;
    }
}
.add-class-btn{
    min-width: fit-content !important;
    height:40px;
    border-radius: 50px;
    border: 2px dot-dash #848488;
    background-color: rgb(222, 221, 221);
    color: black;
    padding: 2px;
    cursor: pointer !important;
}

.box{
    height:40px;
    border-radius: 50px;
    white-space: nowrap;
    width: auto;
    background-color: rgb(255, 255, 255);
    color: black;
    padding: 2px;
    transition: color, background-color 0.4s;
    cursor: pointer!important;
}

.box-selected{
    background-color: rgb(13, 110, 253) !important;
    color: #fff;
}

.box:hover {
    filter: grayscale(0.3);
}
