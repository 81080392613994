/*------------------------------*/
/*----------Key frames----------*/
/*------------------------------*/
@keyframes -scaleBig {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}
@keyframes -scaleLogoBig {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.08);
    }
}

@keyframes -scaleLogoSmall {
    0% {
        transform: scale(1.08);
    }
    100% {
        transform: scale(1);
    }
}


@keyframes -scaleSmall {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes -scaleLogoBig {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.15);
    }
}

@keyframes -scaleLogoSmall {
    0% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes -bop {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-8px);
    }
}

/*------------------------------*/
/*-------------CSS--------------*/
/*------------------------------*/

.rounded-0{
    border-radius: 0 !important;
}

.scale-logo-big {
    animation: -scaleLogoBig 0.5s forwards;
}
.scale-logo-small {
    animation: -scaleLogoSmall 0.5s forwards;
}

.white-border {
    font-weight: bolder !important;
}

.nav-top-active{
    color: #ffffff !important;
    /*color: #fff3f3 !important;*/
}
.nav-top-active:hover{
    border-bottom: 3px solid darkblue!important;
    transition: color 0.4s;
}
.nav-top-inactive{
    color: #fff3f3 !important;
}
.nav-top-inactive:hover{
    border-bottom: 3px solid #f6f4f2 !important;
    transition: color 0.4s;
}
.nav-fa-icon {
    font-size: 2rem !important;
}

.border-bottom-active {
    border-bottom: 1px solid #ffffff !important;
    height: 100px;
    transition: all .5s ease-in-out;
    background-color: rgba(0, 0, 0, 0);
}

.border-bottom-inactive {
    transition: all .5s ease-in-out;
    height: 100px;
    border-bottom: 0 !important;
    background-color: rgb(1, 15, 96);;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.40) !important;
}

.scale:hover {
    animation: -scaleBig 300ms forwards;
}
.navbar-underlay {
    height: 100px;
}

.scale:not(:hover) {
    animation: -scaleSmall 300ms forwards;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.btn-close-bg-white{
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat
}
.align-super{
    vertical-align: super;
}

.bop:hover {
    animation: -bop 0.2s forwards !important;
}

.nav-logo {
    animation: -scaleLogo 1s forwards;
}
.sidebar-width{
    width: 15%;
}
.bg-darkblue{
    background-color: rgb(1, 15, 96) !important;
}
.sidebar-header{
    height: 92px!important;
}

/*------------------------------*/
/*------------Media-------------*/
/*------------------------------*/

@media (max-width: 1200px) {

    .sidebar-width{
        width: 200px !important;
    }

    .nav-top-active{
        color: #fff3f3 !important;
        border-bottom: 3px solid #f6f4f2 !important;
        transition: color 0.4s;
    }

    .border-bottom-active {
        border-bottom: 0 !important;
        height: auto;
        background-color: rgb(1, 15, 96);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.40) !important;
    }
    .border-bottom-inactive {
        border-bottom: 0 !important;
        height: auto;
        background-color: rgb(1, 15, 96);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.40) !important;
    }
}


