.message-box {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}